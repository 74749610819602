import React from 'react';
import { navigate } from 'gatsby';
import { DefaultType } from 'types/default';

import { OtherPostsType } from 'components/organisms/know-how-slider';
import { CategoryType, TagType } from 'components/organisms/post-header';
import Link from 'components/atoms/link';
import { WpImageType } from 'components/atoms/wp-image';

import {
  StyledButton,
  StyledPostInfo,
  StyledTitle,
  StyledWpImage,
  StyledWrapper,
  StyledWrapperImage,
} from './article-card.styles';

export type PostCard = {
  title: string;
  uri: string;
  featuredImage?: {
    node: WpImageType;
  };
  date?: string;
  id?: string;
  tags?: {
    nodes: TagType[];
  };
  categories?: {
    nodes: CategoryType[];
  };
} & DefaultType;

const ArticleCard = ({
  title,
  tags,
  uri,
  date,
  featuredImage,
  postButton,
  categories,
  margin,
  maxWidth,
}: PostCard & Pick<OtherPostsType, 'postButton'>) => {
  const tag = tags?.nodes[0]?.name;
  const category = categories?.nodes[0].name;
  const tagUri = tags?.nodes[0]?.uri;
  const categorySlug = categories?.nodes[0]?.slug;
  const linkTag = `/know-how/${categorySlug}${tagUri}`;

  return (
    <StyledWrapper margin={margin} maxWidth={maxWidth}>
      {featuredImage?.node ? (
        <StyledWrapperImage data-link={uri || ''} onClick={() => navigate(uri || '')}>
          <StyledWpImage {...featuredImage.node} />
        </StyledWrapperImage>
      ) : null}
      {tag || category ? (
        <StyledPostInfo variant="title5" color="gray60">
          {linkTag ? (
            <Link internal={{ uri: linkTag }} ariaLabel={`${tag}`}>
              {tag}
            </Link>
          ) : (
            `${tag}`
          )}
          {!tag && category ? `${category}` : null}

          {tag && category ? `, ${category}` : null}

          {date ? <span>{date}</span> : null}
        </StyledPostInfo>
      ) : null}
      <Link internal={{ uri }} ariaLabel={title}>
        <StyledTitle
          variant="title3"
          color="gray100"
          component="h3"
          data-link={uri || ''}
          onClick={() => navigate(uri || '')}
        >
          {title}
        </StyledTitle>
      </Link>

      <StyledButton
        {...{
          icon: { name: 'arrow-right' },
          label: postButton.label,
          link: {
            internal: { uri },
            ariaLabel: postButton?.ariaLabel || title,
          },
        }}
        width="max-content"
        variant="reversed-underline"
        className="card-with-icon__button"
      />
    </StyledWrapper>
  );
};

export default ArticleCard;
